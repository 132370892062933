
      try {
        window.BUI_BUILD_VERSION = window.BUI_BUILD_VERSION || {};
        window.BUI_BUILD_VERSION["eimos"] = window.BUI_BUILD_VERSION["eimos"] || {};
        window.BUI_BUILD_VERSION["eimos"].commitId = "4e18d8f";
        window.BUI_BUILD_VERSION["eimos"].branchName = "HEAD";
        window.BUI_BUILD_VERSION["eimos"].version = "PDT_20240714";
        window.BUI_BUILD_VERSION["eimos"].date = "2024-10-29 21:8:28";
      } catch (e) {
        console.error('BuiBuildVersion error');
        console.error(e);
      }
    