import React, { useEffect, useImperativeHandle, useState } from 'react';
import { loadMicroApp, MicroApp } from 'qiankun';
import cx from 'classnames';
import { Drawer, Modal, Spin } from 'antd';
import { BuiQiankunParamsType, UI_TYPES } from './type';
import TelescopicDrawer from '@/components/FlexDrawer2/TelescopicDrawer';
import * as uuid from 'uuid';
import styles from './index.module.less';
import { useMemoizedFn, useTimeout } from 'ahooks';
import { getDebugMicroAppEntry } from './debugEntry';
import { getJudgeRoute } from '@/utils/getJudgeRoute';
import { OpenParams } from '@/hooks/useQiankunModalStore';
import { getMicroAppEntry } from './utils';
import { log } from '@/utils/log';
import qiankun from 'qiankun';
import { useMicroAppStore } from '@/hooks/useMicroAppStore';

/**
 * 存放qiankun modal id，用于判断是否需要 eimos navigate
 */
export const qiankunModalSet = new Set<string>();

const QiankunModal = React.forwardRef<any, BuiQiankunParamsType>(
  (
    {
      route,
      type,
      onHide,
      onOk: _onOk,
      props,
      saveSuccess,
      notify,
      containerDom,
      customOnClose,
      qiankunId,
      defaultWidth,
      defaultHeight,
      title,
      drawerDirection,
    },
    ref,
  ) => {
    const [microApp, setMicroApp] = useState<MicroApp | undefined>();
    const [containerId] = useState<string>(`bui-qiankun-micro-app-${qiankunId}`);

    const isTaskRoute = getJudgeRoute('lcpTask', route);
    const isModelRoute = getJudgeRoute('lcpModel', route);

    useImperativeHandle(ref, () => ({
      show: () => {
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
    }));

    const mount = () => {
      setIsAppLoading(false);
    };

    const [isAppLoading, setIsAppLoading] = useState<boolean>(true);

    useTimeout(() => {
      setIsAppLoading(false);
    }, 2000);

    // 关闭弹窗
    const onCancel = useMemoizedFn(async () => {
      setOpen(false);
      // 自定义关闭回调
      customOnClose && customOnClose({ qiankunId, closeState: true });
      onHide?.();
      // await unmountMicroApp();
    });

    const onOk = useMemoizedFn(async (value: any) => {
      setOpen(false);
      // 自定义关闭回调
      customOnClose && customOnClose({ qiankunId, closeState: true });
      _onOk?.(value);
      // await unmountMicroApp();
    });

    // 页面弹窗是否展示
    const [open, setOpen] = useState(false);

    useEffect(() => {
      const createLcpView = async () => {
        if (!route) return;
        let routeConfig = route.replace(/((\/)?pro)?\//, '');
        const routeArray = routeConfig.split('/');
        const [microAppName, ...rest] = routeArray;
        const validProAppName = [
          'portal',
          'task',
          'taskDesign',
          'bi',
          'cutler',
          'usercenter',
          'integrate',
          'indicator-parser',
          'indicator',
          'timeline',
          'baseline',
          'integration',
          'data-qualtity',
          'ipscustom',
          'icmcustom',
          'ismp',
          'mdmcustom',
          'mission-order',
          'jurisdiction',
          'platform-maker',
          'data-governance',
          'data-exploration',
          'orchestration',
        ];
        let entry = ``;
        const isNormalMicroApp = validProAppName.includes(microAppName);

        if (!isNormalMicroApp) {
          entry = getMicroAppEntry('appStore');
          routeConfig = `/${routeArray.join('/')}`;
        } else {
          routeConfig = `/${rest.join('/')}`;
          entry = getMicroAppEntry(microAppName);
        }

        log.info('QiankunModal entry', entry);

        // 请不要通过修改以下代码进行调试，如果需要调试请在本地开发环境修改 localStorage 进行开发
        // 如 localStorage.setItem('entry:appStore', 'http://localhost:7777/appStore/index.html');
        // 如 localStorage.setItem('entry:appStore', 'http://localhost:7777');
        // 如 localStorage.setItem('entry:platform-maker', 'http://local.dev.eimos.com:5559/platform-maker/index.html');
        // 如 localStorage.setItem('entry:platform-maker', 'http://local.dev.eimos.com:5559');
        if (process.env.NODE_ENV === 'development') {
          const debugEntry = getDebugMicroAppEntry(microAppName, isNormalMicroApp);
          if (debugEntry) {
            console.info(
              '%c正在使用本地的 entry 打开微应用',
              'color: #0064CE; font-weight: bold;',
              debugEntry,
            );

            entry = debugEntry;
          }
        }

        console.log('route', route);

        await unmountMicroApp();
        const name = uuid.v4();
        qiankunModalSet.add(name);
        const lcpMicroApp = loadMicroApp(
          {
            container: `#${containerId}`,
            name,
            entry,
            props: {
              shouldNavigate: false,
              qiankun,
              ...props,
              pageConfig: {
                route: routeConfig,
              },

              openTab: (params: { title?: string; url: string }) => {
                log.warn('todo', 'openTab', params);
                // buiAppStore?.openTab(params);
              },

              open(params: OpenParams) {
                log.warn('todo', 'open', params);
                // useQiankunModalStore.getState().open(params);
              },

              refreshTab: () => {
                useMicroAppStore.getState().refresh();
                // return buiAppStore?.refreshTab();
              },
              getUser: () => {
                // return buiAppStore?.getUser();
              },
              closeTab: () => {
                // return buiAppStore?.closeTab();
              },
              openType: type,
              async closeModal(value: any) {
                if (value != undefined) {
                  return await onOk(value);
                }
                return onCancel();
              },
              confirmModal: async (value: string) => {
                return await onOk(value);
              },
              notify,
              mount,
              saveSuccess: () => {
                onHide && onHide();
                // 自定义关闭回调
                customOnClose && customOnClose({ qiankunId, closeState: true });
                saveSuccess && saveSuccess();
                setOpen(false);
              },
            },
          },
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetch: async (url, ...args) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // if (url?.endsWith('/static/bui/css/1.0.0/bui.min.css')) {
              //   return {
              //     async text() {
              //       return '';
              //     },
              //   };
              // }
              const staticFiles = [
                '/static/bui/css/1.0.0/buifont.css',
                '/static/bui/lcp_assets/lcp-pro/1.0.0/lcp-pro.min.css',
                '/static/bui/css/1.0.0/common/all.buifont-buiMin-0.0.0.1.css',
                '/static/bui/css/1.0.0/bui.min.css',
                '/static/js/lodash/4.6.1/lodash.min.js',
                '/static/js/moment/2.29.4/min/moment.min.js',
                // '/static/js/qiankun/2.10.12/qiankun.min.js'
                '/static/js/prop-types/15.7.2/prop-types.js',
                '/static/js/react@17.0.2/umd/react.production.min.js',
                '/static/js/react@17.0.2/umd/react.development.js',
                '/static/js/react-dom@17.0.2/umd/react-dom.development.js',
                '/static/js/react@17.0.2/umd/react.development.min.js',
                '/static/js/react-dom@17.0.2/umd/react-dom.production.min.js',
                '/static/js/react-dom@17.0.2/umd/react-dom.development.min.js',
                '/static/js/icons/4.7.0/dist/index.umd.min.js',
                '/static/js/antd/4.24.7/dist/antd.min.js',
                // '/lcp-asset/lcp-pro.min.js',
                '/static/js/common/all.min-react-reactDom-antd-proptype-qiankun-index_umd-momentjs-0.0.1.js',
                '/static/bui/css/1.0.0/tailwind.min.css',
                '/static/js/ahooks/3.7.6/ahooks.js',
              ];
              const isStaticFile = staticFiles.some((file) => {
                return (url as any)?.endsWith(file);
              });
              if (isStaticFile) {
                return {
                  async text() {
                    return '';
                  },
                };
              }
              return window.fetch(url, ...args);
            },
          },
        );
        setMicroApp(lcpMicroApp);
      };
      if (open) {
        createLcpView();
      }
    }, [route, type, open, props]);

    // 卸载微应用
    const unmountMicroApp = async () => {
      const microAppStatus = microApp?.getStatus();
      if (microAppStatus !== 'NOT_MOUNTED') {
        microApp?.unmount && (await microApp.unmount());
      }
    };
    if (type === UI_TYPES.RIGHTTELESCOPICDRAWER) {
      return (
        <TelescopicDrawer
          title={title}
          open={open}
          onCancel={onCancel}
          destroyOnClose={true}
          mask={false}
          width={defaultWidth}
          contentZindex={1000}
          getContainer={containerDom}
          className={cx(styles['qiankun-modal'], styles['qiankun-spin-container'])}
        >
          <Spin spinning={isAppLoading}>
            <div id={containerId} className={styles['bui-qiankun-micro-app']}></div>
          </Spin>
        </TelescopicDrawer>
      );
    }

    if (type === UI_TYPES.BOTTOMSUCTIONDRAWER) {
      return (
        <TelescopicDrawer
          title={title}
          open={open}
          onCancel={onCancel}
          destroyOnClose={true}
          mask={false}
          position="bottom"
          height={defaultHeight}
          getContainer={containerDom}
        >
          <Spin spinning={isAppLoading}>
            <div id={containerId} className={styles['bui-qiankun-micro-app']}></div>
          </Spin>
        </TelescopicDrawer>
      );
    }

    if (type === UI_TYPES.MODAL) {
      return (
        <Modal
          title={title}
          open={open}
          onCancel={onCancel}
          destroyOnClose={true}
          footer={null}
          bodyStyle={{ padding: '24px 24px' }}
          width={defaultWidth ?? 300}
          maskTransitionName=""
          transitionName=""
          className={styles['qiankun-modal']}
        >
          <Spin spinning={isAppLoading}>
            <div id={containerId} className={styles['bui-qiankun-micro-app']}></div>
          </Spin>
        </Modal>
      );
    }

    return (
      <Drawer
        title={title}
        open={open}
        placement={drawerDirection || 'right'}
        onClose={onCancel}
        destroyOnClose={true}
        width={defaultWidth ?? 300}
        className={cx(
          styles['no-animation-drawer'],
          styles['qiankun-modal'],
          isTaskRoute || isModelRoute ? styles['lcp-task-drawer'] : undefined,
        )}
      >
        <Spin spinning={isAppLoading}>
          <div id={containerId} className={styles['bui-qiankun-micro-app']}></div>
        </Spin>
      </Drawer>
    );
  },
);
export default QiankunModal;
