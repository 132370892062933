import { Button, ConfigProvider, Empty, Skeleton, Space, Tag, notification } from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import cx from 'classnames';
import useQiankun from './hooks/useQiankun';
import routes from './routes';
import styles from './App.module.less';
import { AppLayout } from './layout/components/AppLayout/AppLayout';
import { useAppInit } from './hooks/useAppInit';
import { isMobile } from './utils/isMobile';
import { appName, isQiankun } from './config';
import { SuspendButton } from './components/SuspendButton';
import { WaterMark } from './components/WaterMark';
import { useProxy } from './infra/proxy';
import { QiankunModals } from './components/QiankunModals';
import { LngProvider } from '@bui/i18n';
import { useTranslate } from './hooks/useTranslate';
import NoPermission from './pages/NoPermission';

const App = (props: Record<any, any>) => {
  if (!isQiankun) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useProxy();
  }
  const element = useRoutes(routes);
  const { pageConfig, shouldNavigate } = props;
  const route = pageConfig?.route;
  const navigate = useNavigate();
  const t = useTranslate();
  const appInited = useAppInit();
  const location = useLocation();

  const { setQiankun } = useQiankun();

  useEffect(() => {
    if (props) {
      setQiankun(props);
    }
  }, []);

  // memory 路由手动跳转
  useEffect(() => {
    if (route) {
      navigate(`/${appName}${route}`);
    }
  }, [route]);
  // 子应用路由内跳转更改浏览器地址url
  useEffect(() => {
    if (!shouldNavigate) {
      return;
    }
    const { pathname, search } = location;
    let fullPath = `${pathname}${search}`;
    if (fullPath !== '' && fullPath !== '/' && !fullPath.startsWith('/pro')) {
      fullPath = `/pro${fullPath}`;
    }
    props?.setGlobalState &&
      props.setGlobalState?.({
        ROUTE_CHANGE: fullPath,
      });
  }, [location.pathname, location.search, shouldNavigate]);
  const permissionLoading = () => {
    const top = (
      <div style={{ position: 'fixed', top: 0, width: '100%' }}>
        <div style={{ padding: 8, paddingLeft: 40 }}>
          <Space>
            <Skeleton.Avatar active={true} />
            <Skeleton.Input active={true} style={{ height: 20, marginRight: 100 }} />
            <Skeleton.Button active={true} block={true} style={{ height: 20 }} />
            <Skeleton.Button active={true} block={true} style={{ height: 20 }} />
            <Skeleton.Button active={true} block={true} style={{ height: 20 }} />
            <Skeleton.Button active={true} block={true} style={{ height: 20 }} />
            <Skeleton.Button active={true} block={true} style={{ height: 20 }} />
          </Space>
        </div>
      </div>
    );
    const side = (
      <div style={{ padding: 20 }}>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    );
    return (
      <AppLayout top={top} side={side}>
        <div style={{ padding: 20, width: 800 }}>
          <Skeleton style={{ marginBottom: 20 }} />
          <Skeleton active />
        </div>
      </AppLayout>
    );
  };

  const initError = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          background: '#f1f3f5',
          color: '#60666B',
        }}
      >
        <Empty
          image={require('@/images/502.png')}
          imageStyle={
            {
              // height: 60,
            }
          }
          description={<span>系统错误，请刷新重试或联系管理员</span>}
        >
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('Refresh', '刷新')}
          </Button>
        </Empty>
      </div>
    );
  };

  useEffect(() => {
    if (props.IS_TAB_MICRO_APP && !props.hasPermission) {
      props.closeLoading?.();
    }
  }, []);

  if (props.IS_TAB_MICRO_APP && !props.hasPermission) {
    return <NoPermission />;
  }

  if (!appInited.inited) {
    return permissionLoading();
  }
  if (appInited.error) {
    return initError();
  }
  const isMobileDevice = isMobile();

  const onCloseAllNotification = () => {
    const btn = document.getElementById('eimos-close-all-notification');
    if (btn) {
      btn.style.opacity = '0';
      btn.style.visibility = 'hidden';
    }
    notification.destroy();
  };

  return (
    <div
      className={cx(`sp-${appName}-view-wrapper`, isMobileDevice ? 'EIMOS-MOBILE' : undefined)}
      style={{ width: '100%', height: '100%' }}
    >
      <Tag
        color="#108ee9"
        closable={true}
        className={styles['close-all-notification']}
        id="eimos-close-all-notification"
        style={{ opacity: 0, visibility: 'hidden' }}
        onClick={onCloseAllNotification}
        onClose={onCloseAllNotification}
        // style={{ display: 'none' }}
      >
        全部关闭
      </Tag>
      {isMobileDevice && <SuspendButton />}
      <ConfigProvider locale={zh_CN}>{element}</ConfigProvider>
      <QiankunModals />
      {/* <PermissionProvider
        appCode={appCode}
        url={location.pathname}
        fallback={noPermission as any}
        loading={permissionLoading as any}
      >
      </PermissionProvider> */}
      <WaterMark />
    </div>
  );
};

export default (props: Record<any, any>) => {
  return (
    <LngProvider classes={['eimos']}>
      <App {...props} />
    </LngProvider>
  );
};
